
import { createStore, combineReducers } from 'redux';
import modalReducer from './reducers/modal';
// import cartReducer from './reducers/cart';
// import orderReducer from './reducers/order';

const store = () => {
  const store = createStore(
    combineReducers({
      // cart: cartReducer,
      // order: orderReducer,
      modal: modalReducer,
      modalBanner: modalReducer
    })
  );
  return store;
};

export default store;